<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="creaRapportino()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    <span>Cliente</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{
                                        appuntamento.customers_company
                                            ? appuntamento.customers_company
                                            : `${appuntamento.customers_name} ${appuntamento.customers_last_name}`
                                    }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Progetto / Commessa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    <span>Commessa</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{ appuntamento.projects_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    <span>Data rapportino</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{ dayMonthFormat(presenza.presenze_data_inizio) }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Orari -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    <span>Ora inizio e fine</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{ `${presenza.presenze_ora_inizio} - ${presenza.presenze_ora_fine}` }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="rapportino.note"
                                        placeholder="Indicare qui eventuali servizi non svolti o motivare gli straordinari"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Immagini rapportino -->
                        <ion-item lines="none" class="ion-no-padding" v-if="immaginiRapportino.length != 0">
                            <div class="field">
                                <div class="field_title" position="floating">Immagini</div>
                                <div class="foto_container">
                                    <div v-for="(foto, index) in immaginiRapportino" :key="index" class="single_foto">
                                        <ion-thumbnail>
                                            <!-- <img :src="setImageUrl(foto)" /> -->
                                            <img :src="`data:image/png;base64,${foto.data}`" />
                                        </ion-thumbnail>
                                        <!-- <div @click="deletePhoto(foto.name)" class="remove_photo">Rimuovi</div> -->
                                    </div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions_firme">
                            <button
                                v-if="appuntamento.projects_richiesta_firma_cliente && appuntamento.projects_richiesta_firma_cliente == '1'"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: customerHasSigned }"
                                @click="openSignature('cliente')"
                            >
                                Firma cliente
                            </button>
                            <button
                                v-if="appuntamento.projects_richiesta_firma_operatore && appuntamento.projects_richiesta_firma_operatore == '1'"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: technicianHasSigned }"
                                @click="openSignature('tecnico')"
                            >
                                Firma operatore
                            </button>
                        </div>

                        <div class="action">
                            <div class="btn_allega_foto" @click="openModalPhotos()">Carica foto</div>
                            <button type="submit" class="btn_crea_intervento" :disabled="savingRapportino">
                                Salva rapportino
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonTextarea,
    IonThumbnail,
    IonIcon,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, onMounted } from "vue";

import { openToast } from "@/services/toast";
import { dayMonthFormat } from "@/services/utils";
import Loading from "@/services/loading";

import apiTecnici from "@/services/tecnici";
import apiRapportini from "@/services/rapportini";
//import apiServiziCommessa from "@/custom/services/servizi_commessa";

import moment from "moment";

import ModalFirmaIntervento from "@/components/ModalFirmaIntervento";
import FotoRapportino from "@/components/rapportini/FotoRapportino";

export default defineComponent({
    name: "NuovoRapportinoAppuntamento",
    props: {
        data: {
            type: Object,
        },
        app: {
            type: Object,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonTextarea,
        IonThumbnail,
        IonIcon,
    },
    setup(props, context) {
        /* console.log(props.data);
        console.log(props.app); */
        const presenza = { ...props.data };
        const appuntamento = { ...props.app };
        console.log(presenza);
        console.log(appuntamento);

        const userID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_id;

        const today = moment().format("YYYY-MM-DD");
        //Inizio e fine rapportino coincidono con gli orario della'entrata ed uscita presenza
        const start_hour = presenza.presenze_ora_inizio
            ? moment(`${today} ${presenza.presenze_ora_inizio}`).format("YYYY-MM-DD HH:mm")
            : moment().format("YYYY-MM-DD HH:mm");
        const end_hour = presenza.presenze_ora_fine
            ? moment(`${today} ${presenza.presenze_ora_fine}`).format("YYYY-MM-DD HH:mm")
            : moment().format("YYYY-MM-DD HH:mm");

        const rapportino = reactive({
            cliente: appuntamento.appuntamenti_cliente,
            appuntamento: appuntamento.appuntamenti_id,
            commessa: appuntamento.appuntamenti_impianto,
            data: moment(appuntamento.appuntamenti_giorno).format("YYYY-MM-DD"),
            ora_inizio: moment(start_hour).toISOString(),
            ora_fine: moment(end_hour).toISOString(),
            operatori: [userID],
            note: "",
            immagini: [],
            firma_tecnico: "",
            firma_cliente: "",
        });

        const successResponse = ref(false);

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Open modal to sign
         */
        const technicianHasSigned = ref(false);
        const customerHasSigned = ref(false);

        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: ModalFirmaIntervento,
                componentProps: {
                    data: signatureType,
                    firma: signatureType === "tecnico" ? rapportino.firma_tecnico : rapportino.firma_cliente,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    //Cliente e/o tecnico hanno firmato, cambia classe al button
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            rapportino.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            rapportino.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                    }
                    //Firma cliente e/o tecnico è stata pulita e chiusa la modale, devo togliere la firma da rapportino
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            rapportino.firma_tecnico = "";
                            technicianHasSigned.value = false;
                        } else {
                            rapportino.firma_cliente = "";
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Gestione foto rapportino
         */
        const immaginiRapportino = ref([]);
        async function openModalPhotos() {
            const modal = await modalController.create({
                component: FotoRapportino, //Component to render inside ionic modal
                /* componentProps: {
                    images: immaginiRapportino.value,
                }, */
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            //rapportino.immagini.push(singleFoto);
                            immaginiRapportino.value.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Remove photo from the fotoInterventi reference data array
         */
        function deletePhoto(photo) {
            rapportino.immagini = rapportino.immagini.filter((immagine) => immagine.name !== photo);
        }

        function setImageUrl(path) {
            //return `http://192.168.11.124/ingegno_plan/uploads/${path}`;
            return `${process.env.VUE_APP_BASE_URL}/uploads/${path}`;
        }

        /**
         * ! Create new rapportino
         */
        const savingRapportino = ref(false);

        async function creaRapportino() {
            savingRapportino.value = true;
            //Controllo che ci sia almeno un operatore selezioanto
            if (rapportino.operatori.length === 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza selezionare gli operatori", "toast_danger");
                return;
            }
            //Controllo di essere sempre tra gli operatori selezionati
            const isUSerSelected = rapportino.operatori.includes(userID);
            if (!isUSerSelected && rapportino.operatori.length != 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza essere tra gli operatori selezionati", "toast_danger");
                return;
            }
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(rapportino.ora_inizio);
            const ora_fine = new Date(rapportino.ora_fine);
            if (ora_inizio > ora_fine) {
                savingRapportino.value = false;
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            }

            const ora_inizio_formatted = moment(rapportino.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(rapportino.ora_fine).format("HH:mm");

            const data = new FormData();
            data.append("rapportini_cliente", rapportino.cliente);
            data.append("rapportini_commessa", rapportino.commessa);
            data.append("rapportini_appuntamento_id", rapportino.appuntamento);
            data.append("rapportini_data", rapportino.data);
            data.append("rapportini_ora_inizio", ora_inizio_formatted);
            data.append("rapportini_ora_fine", ora_fine_formatted);
            rapportino.operatori.forEach((tecnico) => {
                data.append("rapportini_operatori[]", tecnico);
            });
            data.append("rapportini_note", rapportino.note);

            /* if (rapportino.immagini.length != 0) {
                rapportino.immagini.forEach((foto) => {
                    data.append("rapportini_immagini[]", foto.data);
                });
            } */

            /* for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return; */

            Loading.simpleLoader("Salvataggio in corso", 10000, true);

            try {
                const response = await apiRapportini.saveRapportino(data);
                if (response.data.status === 8) {
                    Loading.dismissLoader();
                    openToast(response.data.message, "toast_danger");
                } else {
                    //Se ho la presenza salvata nel localstorage devo cancellare il record perchè vuol dire che ho creato il rapportino collegato
                    const presenza_rapportino = JSON.parse(localStorage.getItem("presenza_rapportino"));
                    if (presenza_rapportino && Object.keys(presenza_rapportino).length !== 0) {
                        localStorage.removeItem("presenza_rapportino");
                    }

                    const res = response.data.data[0];
                    //Inserisco le immagini nella relazione
                    if (immaginiRapportino.value.length != 0) {
                        const images = immaginiRapportino.value;
                        const result = await apiRapportini.salvaFotoRapportino(images, res.rapportini_id);
                    }
                    //Svuoto array immagini
                    immaginiRapportino.value = [];

                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res);
                }
            } catch (error) {
                console.error(error);
                Loading.dismissLoader();
                savingRapportino.value = false;
                openToast("Errore durante la creazione del rapportino", "toast_danger");
            } finally {
                Loading.dismissLoader();
                setTimeout(() => {
                    savingRapportino.value = false;
                }, 1000);
            }
        }

        onMounted(() => {
            //loadServiziCommessa();
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            creaRapportino,
            savingRapportino,
            rapportino,
            //operatori,
            presenza,
            appuntamento,
            dayMonthFormat,
            //Gestione immagini
            openModalPhotos,
            deletePhoto,
            //Firme
            openSignature,
            technicianHasSigned,
            customerHasSigned,
            //Immagini
            immaginiRapportino,
            setImageUrl,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 6px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
ion-button {
    --color: #ffffff;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}
.btn_crea_intervento:disabled {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(62, 160, 208);
    color: #ffffff;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.btn_allega_foto {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    color: #ffffff;
    background-color: #06b6d4;
    margin: 24px 0;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

.info_aggiuntive {
    font-size: 14px;
}
</style>
