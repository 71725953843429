<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Ordina materiale</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Prodotti list, triggered with click on select prodotto in form -->
                <Transition name="fade-transition">
                    <div v-show="showProducts" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca materiale" v-model="searchQueryProducts" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="prodotto in searchedProducts"
                                    :key="prodotto.customers_id"
                                    @click="setSelectedProduct(prodotto)"
                                    class="customer"
                                    :class="setActiveProduct(prodotto, selectedProduct)"
                                >
                                    {{ prodotto.fw_products_name }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeProdottoSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <!-- Form ordine materiali -->
                <form @submit.prevent="ordinaMateriali()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    <span>Cliente</span>
                                </div>
                                <div class="value">
                                    {{
                                        appuntamento.customers_company
                                            ? appuntamento.customers_company
                                            : `${appuntamento.customers_name} ${appuntamento.customers_last_name}`
                                    }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Progetto / Commessa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    <span>Commessa</span>
                                </div>
                                <div class="value">
                                    {{ appuntamento.projects_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Prodotti -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Materiali
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </span>
                                    <div @click="openProdottoSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="ordine.prodotti.length != 0">
                                        <div class="container_prodotti">
                                            <div v-for="(prodotto, index) in ordine.prodotti" :key="index" class="prodotto">
                                                <div class="data">{{ prodotto.qty }} - {{ prodotto.name }}</div>
                                                <div class="actions">
                                                    <div @click="changeQuantity(prodotto, 'minus')" class="button button_minus">
                                                        -
                                                    </div>
                                                    <div @click="changeQuantity(prodotto, 'plus')" class="button button_plus">
                                                        +
                                                    </div>

                                                    <div @click="showActionSheet(prodotto)" class="button button_remove">
                                                        <ion-icon slot="start" :icon="close" color="red"></ion-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>Nessun materiale selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="3"
                                        v-model="ordine.note"
                                        placeholder="Inserire note aggiuntive dell'ordine"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <div class="action">
                            <button type="submit" class="btn_crea_intervento" :disabled="isLoading">
                                Invia ordine materiali
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonText,
    IonTextarea,
    IonIcon,
    actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline, close, trash } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, onMounted } from "vue";

import { openToast } from "@/services/toast";
import { dateFormat } from "@/services/utils";
import Loading from "@/services/loading";

import apiProdotti from "@/services/prodotti";
import apiRapportini from "@/services/rapportini";

import apiProdottiCustom from "@/services/prodotti";

import moment from "moment";

export default defineComponent({
    name: "OrdineMaterialeAppuntamento",
    props: {
        data: {
            type: Object,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonText,
        IonTextarea,
        IonIcon,
    },
    setup(props, context) {
        const userID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_id;

        const appuntamento = { ...props.data };

        const ordine = reactive({
            utente: userID,
            cliente: appuntamento.appuntamenti_cliente,
            commessa: appuntamento.appuntamenti_impianto,
            prodotti: [],
            tipo: "14",
            note: "",
        });

        const successResponse = ref(false);

        /**
         * ! Load prodotti
         */
        const prodotti = ref([]);
        async function loadProducts() {
            try {
                const res = await apiProdottiCustom.getProdottiByCommessa(ordine.commessa);
                //const res = await apiProdotti.getProdotti();
                if (res.status === 0) {
                    prodotti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei prodotti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei prodotti", "toast_danger");
            } finally {
                //console.log(customers.value);
            }
        }

        /**
         * ! Gestione selezione prodotti
         */
        const showProducts = ref(false);
        function openProdottoSelection() {
            showProducts.value = true;
        }

        function closeProdottoSelection() {
            showProducts.value = false;
        }

        const searchQueryProducts = ref("");
        const searchedProducts = computed(() => {
            const term = searchQueryProducts.value.replace(/ /g, "");
            return prodotti.value.filter((product) => {
                if (product.fw_products_name) {
                    return (
                        product.fw_products_name
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                }
            });
        });

        const selectedProduct = ref(null);
        function setSelectedProduct(product) {
            //console.log(product);
            if (product) {
                searchQueryProducts.value = "";
                selectedProduct.value = product;
                //Se ho già aggiunto il prodotto devo aumentare al quantità di uno altrimenti lo inserisco
                const productExists = ordine.prodotti.find((el) => el.prodotto_id === product.fw_products_id);

                if (productExists) {
                    productExists.qty += 1;
                } else {
                    ordine.prodotti.push({
                        name: product.fw_products_name,
                        prodotto_id: product.fw_products_id,
                        qty: 1,
                    });
                }
                //console.log(ordine.prodotti);
                showProducts.value = false;
            }
        }

        const setActiveProduct = computed(() => {
            return (product, selectedProduct) => {
                let className = "";
                if (selectedProduct) {
                    if (product.fw_products_id === selectedProduct.fw_products_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Change quantity of selected product
         */
        function changeQuantity(product, mode) {
            if (!product) return;
            if (mode === "plus") {
                product.qty += 1;
            }
            if (mode === "minus" && product.qty > 1) {
                product.qty -= 1;
            }
        }

        /**
         * ! Removed product from selected product
         */
        function removeProduct(prodotto) {
            ordine.prodotti = ordine.prodotti.filter((materiale) => materiale.prodotto_id != prodotto.prodotto_id);
        }

        /**
         * ! Handle delete single materiale
         */
        const showActionSheet = async (materiale) => {
            const actionSheet = await actionSheetController.create({
                header: "Vuoi eliminare il materiale selezionato?",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            removeProduct(materiale);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /**
         * ! Limit prouduct name to 35 charactes
         */
        function setShortProduct(productName) {
            if (!productName) {
                return "-";
            } else {
                const trimmedString = productName.length > 28 ? productName.substring(0, 26) + "..." : productName;
                return trimmedString;
            }
        }

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Create new ordine
         */
        const isLoading = ref(false);
        async function ordinaMateriali() {
            isLoading.value = true;
            //Controllo che ci sia il cliente
            if (!ordine.cliente) {
                openToast("Non puoi creare l'ordine senza selezionare il cliente", "toast_danger");
                isLoading.value = false;
                return;
            }
            //Controllo che ci sia la commessa
            if (!ordine.commessa) {
                openToast("Non puoi creare l'ordine senza selezionare la commessa", "toast_danger");
                isLoading.value = false;
                return;
            }
            //Controllo che ci sia almeno un prodotto
            if (ordine.prodotti.length == 0) {
                openToast("Non puoi creare l'ordine senza selezionare i materiali", "toast_danger");
                isLoading.value = false;
                return;
            }
            //Controllo che ci sia il tipo di documento da creare
            if (!ordine.tipo) {
                openToast("Non puoi creare l'ordine senza selezionare la tipologia", "toast_danger");
                isLoading.value = false;
                return;
            }

            Loading.simpleLoader("Salvataggio in corso", 10000, true);

            const data = new FormData();
            data.append("utente", ordine.utente);
            data.append("tipo", ordine.tipo);
            data.append("cliente", ordine.cliente);
            data.append("commessa", ordine.commessa);
            data.append("prodotti", JSON.stringify(ordine.prodotti));
            data.append("note_generiche", ordine.note);

            try {
                const response = await apiProdotti.saveOrdineMateriale(data);
                if (response.data === 0) {
                    successResponse.value = false;
                    openToast(response.txt, "toast_danger");
                } else {
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, response.data);
                }
            } catch (error) {
                Loading.dismissLoader();
                console.error(error);
                openToast("Errore durante la creazione dell'ordine", "toast_danger");
            } finally {
                Loading.dismissLoader();
                isLoading.value = false;
            }
        }

        onMounted(() => {
            loadProducts();
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            trash,
            //appuntamento data
            appuntamento,
            //ordine
            ordinaMateriali,
            ordine,
            // Prodotti
            searchQueryProducts,
            searchedProducts,
            showProducts,
            openProdottoSelection,
            closeProdottoSelection,
            selectedProduct,
            setActiveProduct,
            setSelectedProduct,
            //Handle quantity
            changeQuantity,
            //Remove product
            showActionSheet,
            setShortProduct,
            //loading
            isLoading,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}
.btn_crea_intervento:disabled {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
    opacity: 0.5;
}
ion-button {
    --color: #ffffff;
}

.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_firma {
    width: 47%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
    margin-bottom: 16px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

/* .Prodotti selezionati */
.container_prodotti {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 12px;
}
.container_prodotti .prodotto {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
}
.container_prodotti .prodotto .data {
    font-size: 12px;
}

.container_prodotti .prodotto .actions {
    display: flex;
    margin-left: 16px;
}

.container_prodotti .prodotto .button {
    width: 28px;
    height: 28px;
    display: flex;
    font-weight: bold;
    font-size: 16px;
    justify-content: center;
    align-items: baseline;
    background: #086fa3;
    color: white;
}
.container_prodotti .prodotto .button.button_plus {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.container_prodotti .prodotto .button.button_minus {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.container_prodotti .prodotto .button.button_remove {
    width: 28px;
    height: 28px;
    display: flex;
    margin-left: 4px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    background: #c1162d;
    color: white;
}
</style>
